import React from 'react'
import Confirmation from './confirmation'
import { graphql } from 'gatsby'
const ConfirmationAjPage = Confirmation

export default ConfirmationAjPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
